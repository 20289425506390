@import '/home/node/app/config/../src/styles/config/_variables.scss';
.qrosscheck {

  .nav-mobile {
    position: fixed;
    width: 100%;
    z-index: 4;

    .nav-img {
      height: 40px;
      width: 200px;
    }

    .navigation-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 40px;
      background-color: white;
      overflow: hidden;
      font-size: 1rem;

      transition: height 0.26s ease;
      -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);

      &.open {
        height: 100vh;
      }

      .nav-drop-icon {
        color: $color-text-nav;
      }

      .additional-menus {
        border-left: 1px solid;
        padding-left: 1.5rem;
      }

      .navbar-mobile {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 100%;
      }

      .mobile-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 50px;
        min-height: 100vh;
        width: 100%;

        .nav-link, .nav-link-item {
          display: flex;
          justify-content: left;
          color: $color-text-nav;
          cursor: pointer;

          .header {
            display: flex;
            align-items: center;
          }

          &:hover {
            color: $color-primary;
          }
        }

        .nav-link {
          font-weight: 500;
          padding: 0.5rem 1.5rem 0.5rem 0;
        }
        
        .single-link {
          flex-direction: column;
        }

        .dropdown {
          display: block;
          flex-direction: column;

          overflow: hidden;
          width: 100%;
          max-height: 0;

          transform: scaleY(0);    
          transform-origin: top;
          transition: transform 0.26s ease, max-height 0.26s ease,;

          .nav-link-item {
            font-weight: 300;
            padding: 0.5rem 0 0.5rem 1rem;
          }

          &.open {
            transform: scaleY(1);
            max-height: 200px;
          }

          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
}