$color-white: #FFF;
$color-black: #000;

$color-primary: #0D5AA7;
$color-primary2: #0D5AA7;
$color-secondary: #4A148C;
$color-error: #FF0000;
$color-amber: #FF9900;
$color-ok: #219615;

$color-top-nav: #FFF;
$color-lower-nav: #0D5AA7;
$color-footer: #FFF;
$color-aside-background: #FFF;
$color-aside-deep: #FFF;

$color-text-nav: #1A1A1A;
$color-text-general: #1A1A1A;
$color-text-menu: #0D5AA7;
$color-text-header: #0D5AA7;
$color-text-subheader: #B3B3B3;
$color-text-header-lg: #4D4D4D;
$color-text-header-md: #333;
$color-text-header-sm: #000;

$color-link: #0D5AA7;
$color-divider: #999;
$color-tr-odd: #F2F2F2;

$color-loader: #0D5AA7;
$color-loader-secondary: #E0E0E0;
$color-background-loader: #F5F5F5;

$color-expiration-dashboard: linear-gradient(#e66465, #9198e5);
$color-weather-current-tab-dashboard: #f1f1f1;
