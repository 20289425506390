h1 {
  font-family: $font-family-regular;
  font-size: 2.2em;
  color: $color-text-header;
  font-weight: 600;
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  font-family: $font-family-regular;
  font-size: 1.8em;
  font-weight: 500;
  color: $color-text-header-lg;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}

h3 {
  font-family: $font-family-regular;
  font-size: 1.5em;
  font-weight: 500;
  color: $color-text-header-lg;
  margin-top: 1em;
  margin-bottom: 1em;
}

h4 {
  font-family: $font-family-regular;
  font-size: 1.2em;
  font-weight: 400;
  color: $color-text-header-lg;
  margin-top: 1em;
  margin-bottom: 1em;
}

h5 {
  font-family: $font-family-regular;
  font-size: 1em;
  font-weight: 300;
  color: $color-text-header-md;
  margin-top: 1em;
  margin-bottom: 1em;
}

h6 {
  font-family: $font-family-regular;
  font-size: .8em;
  font-weight: 300;
  color: $color-text-header-sm;
  margin-top: 1em;
  margin-bottom: 1em;
}

p {
  font-family: $font-family-regular;
  font-weight: 300;
  color: $color-text-general;
  font-size: 1em;
  line-height: 2em;
}

a {
  font-family: $font-family-regular;
  font-size: 1em;
  color: $color-link;

  &:hover {
    color: $color-link;
    cursor: pointer;
  }
}

b,
strong {
  font-weight: bold;
}

.error-text {
  color: $color-error;
  font-size: 1em;
}
