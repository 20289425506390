@import '/home/node/app/config/../src/styles/config/_variables.scss';
.react-datepicker__input-container {
  display: block !important;
}

.react-datepicker-popper {
  width: 313px;
}

 @media screen and (max-width: 340px) {
  .react-datepicker-popper {
    width: 313px;
    transform: scale(0.85) !important;
    margin-top: 42px !important;
  }
 }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 68px !important;
  overflow-x: unset !important;
  margin: 0 !important;
  text-align: center !important;
}

li.react-datepicker__time-list-item--selected {
  width: 50px;
}

li.react-datepicker__time-list-item {
  width: 50px;
}

.react-datepicker-wrapper {
  display: block !important;
}

.full-width-picker {
  width: 100% !important;
}

.dialog-width-picker {
  width: 150px !important;
  margin-right: 30px !important;
}

.root {
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin-top: 5px;
  margin-bottom: 4px;
}

.root.full-width {
  width: 100%;
}
