%loader {
  @include spin-animation;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border: 16px solid $color-loader-secondary;
  border-top: 16px solid $color-loader;
  border-radius: 50%;
}

.lock-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-background-loader;
}

.fixed-loader {
  @extend %loader;
  position: fixed;
  z-index: 1000;
  top: 30%;
  left: 50%;
  margin-left: -60px;
}

.loader-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.small-loader {
  @extend %loader;
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  border: 3px solid #E0E0E0;
  border-top: 3px solid #0D5AA7;
}

.medium-loader {
  @extend %loader;
  width: 40px;
  height: 40px;
  margin: 0 10px 0 0;
  border: 5px solid #E0E0E0;
  border-top: 5px solid #0D5AA7;
}