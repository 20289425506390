@import '/home/node/app/config/../src/styles/config/_variables.scss';
.logbook-wrapper {
  .add-logbook {
    display: flex;
    align-items: center;
  }

  .verticalDivider {
     border-right:1px solid #5B646B;
     height:60px;
  }
}