@import '/home/node/app/config/../src/styles/config/_variables.scss';
.qrosscheck {
  header.navigation {
    position: absolute;
    z-index: 4;
    width: 100%;
    top: 0;

    .nav-classic {
      .nav-img {
        height: 50px;
        width: 250px;
      }

      .nav-wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        height: 50px;
        background-color: $color-top-nav;
        -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.5);
      }

      nav {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 2;
        height: 28px;

        &.nav-lower {
          background-color: $color-lower-nav;
          z-index: 2;
          font-weight: 500;

          .nav-link-item {
            position: relative;
            color: white;
          }

          .nav-link {
            position: relative;
            color: white;

            &.single-link {

              &:hover {
                color: $color-primary;

                .nav-link-item {
                  color: $color-primary;
                }
              }
            }

            .nav-drop-icon {
              color: white;
            }

            &:hover {
              color: $color-text-nav;
              background-color: white;

              .nav-link-item {
                position: relative;
                color: $color-text-nav;

                &:hover {
                  color: $color-primary;
                }
              }

              .dropdown {
                transform: scaleY(1);
                font-weight: 300;
              }

              .nav-drop-icon {
                color: $color-text-nav;
              }
            }

            .dropdown {
              background-color: white;
              position: absolute;
              top: 28px;
              right: 0;
              overflow: hidden;
              width: 100%;

              transform: scaleY(0);    
              transform-origin: top;
              transition: transform 0.26s ease;

              box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), -1px 3px 2px rgba(0, 0, 0, 0.1);

              &:hover {
                color: $color-primary;
              }
            }
          }
        }

        .nav-link, .nav-link-item {
          height: 100%;
          padding: 0.5rem 0.4rem;
          display: flex;
          align-items: center;
          justify-content: left;
          color: $color-text-nav;
          cursor: pointer;

          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }
}
