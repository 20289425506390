@import '/home/node/app/config/../src/styles/config/_variables.scss';
.question-background {
  tr.answer-correct {
    background-color: $color-ok;
  }
  tr.answer-wrong {
    background-color: $color-error;
  }
  tr.answer-standard:nth-child(odd) {
    background-color: $color-tr-odd;
  }
  tr.answer-another {
    background-color: white;
  }
}