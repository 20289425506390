@import '/home/node/app/config/../src/styles/config/_variables.scss';
.table-wrapper {
  .column-sort-arrow {
    cursor: pointer;
  }

  .filter-icon-place {
    display: inline-flex;
  }

  .table-row-link {
    &:hover {
      text-decoration: none;
      color: $color-text-general;
    }

    text-decoration: none;
    color: $color-text-general;
  }

  .filterIsUsed {
    color: $color-primary
  }
}

.icons-display-wrapper {
  display: inline-flex;

  button {
   display: inline-table;
  }
}
