@import '/home/node/app/config/../src/styles/config/_variables.scss';
.drop-item {
  opacity: 0.5;
  border: dotted 2px rgba(0, 0, 0, 0.54);
}

.desctiption__validation_text {
  color: rgba(244, 67, 54, 0.9);
  font-size: 12px;
}
