@import '/home/node/app/config/../src/styles/config/_variables.scss';
.eLearning {
  .item-completed {
    color: $color-ok;
  }

  .item-pending {
    color: $color-amber;
  }

  .item-not-completed {
    color: inherit;
  }
}