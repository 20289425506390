// sass-lint:disable no-vendor-prefixes
// sass-lint:disable no-misspelled-properties
// sass-lint:disable no-color-literals

@mixin box-shadow($type, $x, $y, $z, $r, $g, $b, $a) {
  -webkit-box-shadow: $type $x $y $z rgba($r, $g, $b, $a);
  box-shadow: $type $x $y $z rgba($r, $g, $b, $a);
}

@mixin fadein-animation {
  -webkit-animation: fadein 1s; // Safari and Chrome
  -moz-animation: fadein 1s; // Firefox
  -o-animation: fadein 1s; // Opera
  animation: fadein 1s;
}

@mixin blink-animation($x) {
  -webkit-animation: blinker $x linear infinite; // Safari and Chrome
  -moz-animation: blinker $x linear infinite; // Firefox
  -o-animation: blinker $x linear infinite; // Opera
  animation: blinker $x linear infinite;
}

@mixin spin-animation {
  -webkit-animation: spin 2s linear infinite; // Safari and Chrome
  -moz-animation: spin 2s linear infinite; // Firefox
  -o-animation: spin 2s linear infinite; // Opera
  animation: spin 2s linear infinite;
}

@mixin noselect {
  cursor: default;
  -webkit-user-select: none; // Chrome/Safari/Opera
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version

  -webkit-touch-callout: none; // iOS Safari
}

// fade-in animation
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  // Firefox
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  // Safari and Chrome
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  // Opera
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// blinker animation
@keyframes blinker {
  50% {
    opacity: .2;
  }
}

@-moz-keyframes blinker {
  // Firefox
  50% {
    opacity: .2;
  }
}

@-webkit-keyframes blinker {
  // Safari and Chrome
  50% {
    opacity: .2;
  }
}

@-o-keyframes blinker {
  // Opera
  50% {
    opacity: .2;
  }
}

// spin animation
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  // Firefox
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  // Safari and Chrome
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  // Opera
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
