@import '/home/node/app/config/../src/styles/config/_variables.scss';
$resource-background-color: #D3E5F6;
$resource-border-width: 10px;
$headers-background-color: #0D5AA7;
$resource-header-color: #0D5AA7;
$booking-buttons: #0D5AA7;


.booking-scheduler {
  display: flex;
  justify-content: center;

  .overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    font-size: 0.8em;
    line-height: 0.8em;
  }

  table:nth-child(1) {
    border: 0;
  }

  td {
    vertical-align: middle;
  }

  th {
    vertical-align: middle;
  }

  .ant-popover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .timeline-event {
    background: white;
  }

  .resource-view {
    div:first-of-type {
      border: 0 !important;
    }

    th {
      color: $resource-header-color;
    }

    div:nth-of-type(2) {
      background: $resource-background-color;
      border-left: $resource-border-width solid $resource-background-color;
      border-right: $resource-border-width solid $resource-background-color;
      border-bottom: 2px solid $resource-background-color;
      border-radius: $resource-border-width / 2 0 0 $resource-border-width / 2;

      tr {
        border-bottom: 1px solid white;

        &:last-of-type {
          border: 0;
        }
      }
    }
  }


  .scheduler-view {
    div:first-of-type {
      border: 0 !important;
    }

    .scheduler-bg-table {

      th {
        position: relative;
        background: $headers-background-color !important;
        color: white !important;

        &:after {
          position: absolute;
          content: '';
          display: block;
          background: repeating-linear-gradient(
                          90deg,
                          $headers-background-color,
                          $headers-background-color 9px,
                          white 9px,
                          white 10px
          );
          width: 100%;
          height: 6px;
          bottom: 0;
        }


          &:first-of-type {
            background: purple;
            padding: 1px;
          }

        &:nth-of-type(1) {
          border-radius: 4px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 4px 0 0;
        }
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    color: $booking-buttons;
    border-color: $booking-buttons;
  }
}
