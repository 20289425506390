@import '/home/node/app/config/../src/styles/config/_variables.scss';
.courses {
  display: flex;
  width: 100%;
  align-items: center;
  line-height: 1.5em;

  .table-row-cell {
    vertical-align: middle;
  }
}

.assigned-course-details {
  .completed-lesson {
    color: $color-ok;
  }

  .pending-lesson {
    color: $color-amber;
  }

  .failed-lesson {
    color: $color-error;
  }


  .table-row-link {
    &:hover {
      text-decoration: none;
      color: $color-text-general;
    }

    text-decoration: none;
    color: $color-text-general;
  }
}

.lesson-item-details {
  ul {
    list-style: disc;
    list-style-position: inside;
    line-height: 1.4;
  }

  ol {
    list-style: decimal;
    list-style-position: inside;
    line-height: 1.4;
  }

  em {
    font-style: italic;
  }

  ol > ol{
    margin-left:20px;
  }

  ul > ul {
    margin-left:20px;
  }
}

.item-completed {
  color: $color-ok;
}

.item-pending {
  color: $color-amber;
}

.item-not-completed {
  color: inherit;
}
