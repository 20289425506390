.custom-upload-button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  overflow: visible;
  border-radius: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
  font: 400 13.3333px Arial;
  -webkit-writing-mode: horizontal-tb !important;

  color: inherit;
  cursor: pointer;
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  outline: none;
  position: relative;
  user-select: none;
  align-items: center;
  border-radius: 0;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  flex: 0 0 auto;
  width: 48px;
  color: rgba(0, 0, 0, 0.54);
  height: 48px;
  padding: 0;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.file-inputs {
  .custom-upload-button { overflow: hidden }
}

.custom-upload-button-wrapper {
  position: relative;
  overflow: hidden;
}

.custom-upload-icon-button {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}

.custom-upload-icon-button-primary {
  width: 1em;
  color: rgba(0, 0, 0, 0.54);
  height: 1em;
  overflow: hidden;
  font-size: 24px;
  user-select: none;
  flex-shrink: 0;
}

.custom-upload-touch-button {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  position: absolute;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}