@import '/home/node/app/config/../src/styles/config/_variables.scss';
.authentication,
.booking-request {
  &.page-content {
    background-color: #f2f2f2;
  }

  .full-height {
    height: 100vh;
  }

  .input-row {
    display: flex;
    justify-content: center;
  }

  .languages {
    position: absolute;
    top: 30px;
    right: 50px;

    a {
      align-items: center;
      display: flex;
    }
  }
}
