@import '/home/node/app/config/../src/styles/config/_variables.scss';
.qrosscheck {
  background-color: #f2f2f2;

  .content-wrapper {
    min-height: 100vh;
  }

  footer {
    position: absolute;
    display: none;
    bottom: 0;
    z-index: 3;
    width: 100%;
    height: 2em;
    font-size: 0.9rem;
    background-color: $color-footer;
    -webkit-box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.5);

    grid-template-columns: 1fr 5fr 1fr;

    align-items: center;
    text-align: center;

    .footer-side {
      min-width: 150px;
    }

    .logo {
      text-align: center;
      position: relative;
      align-self: center;

      .logo-img {
        background-color: white;
        position: relative;
        height: 22px;
      }

      .logo-hr {
        position: absolute;
        width: 100%;
        top: 50%;
        margin: 0;
        border-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .print-icon {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 2;
  }

  .nav-mobile {
    display: none;
  }
}

@media (max-width: 1500px) {
  .qrosscheck {
    nav {
      font-size: 0.8rem;
    }

    .footer {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 1200px) {
  .qrosscheck {
    nav {
      font-size: 0.75rem;
    }

    .nav-text {
      display: none;
    }

    .footer {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 992px) {
  .qrosscheck {
    .nav-mobile {
      display: block;
    }

    .nav-classic {
      display: none;
    }
  }
}

@media print {
  .qrosscheck {
    background-image: none !important;
  }
}
