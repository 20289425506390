@import '/home/node/app/config/../src/styles/config/_variables.scss';
.dashboard {
  .date {
    font-size: 1.3em;
    font-weight: 500;
    font-style: normal;
    color: $color-divider
  }

  .notification-icon {
    font-size: 2.2em;
  }

  .dashboard-tab-title {
    header {
      &.header-dashboard {
        border-left: 2px solid $color-top-nav;
        padding-left: 1em;

        span {
          &.span-dashboard {
            span-dashboard {
              display: flex;
              align-items: center;
              font-size: 2em;
            }

            h4 {
              &.h4-dashboard {
                margin: .5em .5em;
              }
            }
          }
        }
      }
    }
  }

  .current-tab-label {
    color: $color-weather-current-tab-dashboard;
    font-size: 0.62em;
    margin-right: 30%;
  }

  .tab-label {
    color: $color-weather-current-tab-dashboard;
    font-size: 1.1em;
    margin-right: 5%;
    padding-bottom: 2px;
  }

  .date-aviation {
    float: right;
  }

  .icon-edit {
    font-size: 0.8em;
    padding-bottom: 24px;
  }

  .data-source-name {
    font-weight: bolder;
  }

  .dashboard-elearning {
    vertical-align: bottom;
  }

  .elearning-container {
    position: relative;
    vertical-align: bottom;
  }

  .edit-place {
    position: absolute;
    top: 24px;
    right: -394px;
    height: 0;
    z-index: 2;
  }

  .icon-background {
    position: absolute;
    top: 8px;
    right: 23px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: white;
    z-index: 1;

    &:hover {
      border-radius: 50%;
      background-color: white;
      opacity: 0.7;
    }
  }

  .edit-pen-icon {
    font-size: 1em;
  }

  .rss-news-title {
    font-weight: bolder;
    font-size: 1.1em;
    line-height: 17px;
  }

  .rss-news-description {
    line-height: 1.2em;
  }

  .weather-first-location {
    border-top-left-radius: 6px
  }

  .weather-second-location {
    border-top-right-radius: 6px
  }

  .metar {
    line-height: 1.2em;
  }
}
