html {
  font-family: $font-family-regular;
  font-size: 16px;
  font-weight: 300;
  height: 100%;
  width: 100%;
}

a {
  &:hover {
    text-decoration: none;
  }
}

body {
  min-height: 100%;
  color: $color-text-general;
}

.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
}

.h1-subheader {
  color: $color-text-subheader;
  font-size: 0.8em;
  font-weight: 400;
}

.ok-date {
  color: $color-ok;
}

.expiry-date {
  color: $color-amber;
}

.expired-date {
  color: $color-error;
}

.non-safety-related {
  color: $color-secondary;
}

.svg-arrow-hidden svg {
  visibility: hidden;
}

.status-circle {
  border-width: 10px;
  border-style: solid;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.section-title {
  header {
    border-left: 2px solid $color-primary;
    padding-left: 1em;

    span {
      display: flex;
      align-items: center;

      span {
        font-size: 3em;
      }

      h1 {
        margin: .2em .5em;
      }

      .h1-subheader {
        display: inline;
        color: $color-text-subheader;
        font-size: 0.8em;
        font-weight: 400;
      }
    }

    p {
      font-style: italic;
      font-size: .9em;
    }
  }
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}

.table-placeholder {
  opacity: 0.5;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #fff;
  background: linear-gradient(to right, #fff 8%, #eee 18%, #fff 33%);
  position: relative;
}

@media screen {
  .print-header {
    display: none;
  }
}

@media print {
  @page {
      margin: 20mm;  /* this affects the margin in the printer settings */
  }

  .no-print, .no-print * {
      display: none !important;
  }
}
