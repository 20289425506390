@import '/home/node/app/config/../src/styles/config/_variables.scss';
.folders {
  .folder-list-wrapper {
    width: 100%;

    .folder-navbar {
      button:focus {
        outline: 0;
      }
    }
  }

  .folder-list {
    margin-top: 40px;

    .folder-pannel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
      padding-right: 9px;
      cursor: pointer;
      min-height: 48px;

      &:hover {
        background-color: rgba(13, 90, 167, 0.08);
      }
    }
  }

  .folder-pannel-title {
    display: flex;
    align-items: center;
  }
}